import { createBrowserRouter } from 'react-router-dom';
// import React from 'react';
// import ProtectedRoute from './ProtectedRoute';
import App from '../App'; // Adjust the import path as needed
import AboutPage from '../pages/AboutPage'; // Adjust the import path as needed
import ContactPage from '../pages/ContactPage'; // Adjust the import path as needed 
import PricingPage from '../pages/PricingPage';

// Define the routes with type annotations
const Router = createBrowserRouter([
    {
        path: '/',
        element: <App />,
        // element: <App />,
        children: []
    }, 
    {
        path: '/about',
        element: <AboutPage />,
    },  
    {
        path: '/contact',
        element: <ContactPage />,
    },    
    {
        path: '/pricing',
        element: <PricingPage />,
    },  
]);

export default Router;

