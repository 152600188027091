 
import Footer from '../components/Footer';
import Header from '../components/Header';
import aminulimage from "../assets/bannerImg.efc173b44179941b5429.png";
import rafi from "../assets/Kazi_Waliullah.jpg";

const AboutPage = () => {
 
    return (

        <div>

            <div className="max-w-screen-xl mx-auto px-5">

                <Header />

                <div className="mt-16 text-center">

                    <h1 className="text-4xl lg:text-5xl font-bold lg:tracking-tight">About
                    </h1>

                    <p className="text-lg mt-4 text-slate-600">We are a small team.
                    </p>

                </div>

                <div className="flex flex-col gap-3 mx-auto max-w-4xl mt-16">

                    <h2 className="font-bold text-3xl text-gray-800">Empowering the world with ValoXity.
                    </h2>

                    <p className="text-lg leading-relaxed text-slate-500">
                        At Valoxity, we're united by innovation and passion. Our diverse backgrounds and unique experiences drive us to create cutting-edge solutions for our clients. Together, we bring a wealth of expertise, creativity, and dedication that makes Valoxity stand out in the digital world.
                    </p>

                </div>

                <div className="grid md:grid-cols-3 gap-10 mx-auto max-w-4xl mt-12 ">

                    <div className="group">

                        <div className="w-full aspect-square">

                            <picture>
                                <img src={aminulimage} alt="Robert Palmer" sizes="(max-width: 800px) 100vw, 400px" className="w-full rounded-md rounded transition group-hover:-translate-y-1 group-hover:shadow-xl bg-white object-cover object-center aspect-square" width="400" height="400" loading="lazy" decoding="async" />
                            </picture>

                        </div>

                        <div className="mt-4 text-center">
                            <h2 className="text-lg text-gray-800">Aminul Islam  </h2>
                            <h3 className="text-sm text-slate-500"> Software Engineer </h3>
                        </div>

                    </div>
                    <div className="group">

                        <div className="w-full aspect-square">

                            <picture>
                                <img src={rafi} alt="Robert Palmer" sizes="(max-width: 800px) 100vw, 400px" className="w-full rounded-md rounded transition group-hover:-translate-y-1 group-hover:shadow-xl bg-white object-cover object-center aspect-square" width="400" height="400" loading="lazy" decoding="async" />
                            </picture>

                        </div>

                        <div className="mt-4 text-center">
                            <h2 className="text-lg text-gray-800">Kazi Waliullah  </h2>
                            <h3 className="text-sm text-slate-500"> Product Manager </h3>
                        </div>

                    </div>

                </div>

            </div>

            <Footer />

        </div>
    );
};

export default AboutPage;
