import {
  FaHtml5,
  FaReact,
  FaCss3Alt,
} from "react-icons/fa6";
import { 
  PiFileCppBold ,
  PiFileCSharpBold  } from "react-icons/pi";
import { 
  RiTailwindCssFill ,
  RiNextjsLine } from "react-icons/ri";
import { MdOutlinePhp } from "react-icons/md";
import { IoLogoElectron } from "react-icons/io5";
import { FaPython } from "react-icons/fa";
import Footer from './components/Footer'
import Header from './components/Header'

function App() {
  return (


    <div>


      <div className="max-w-screen-xl mx-auto px-5">

        <Header />
        <main className="grid lg:grid-cols-2 place-items-center pt-16 pb-8 md:pt-12 md:pb-24">
          <div className="py-6 md:order-1 hidden md:block">
            <picture>
              <source type="image/webp" sizes="(max-width: 800px) 100vw, 620px" />
              <img src="https://astroship.web3templates.com/_astro/hero.DlKDY3ml_Z1VhsC8.webp" alt="Astronaut in the air" sizes="(max-width: 800px) 100vw, 620px" loading="eager" width="520" height="424" decoding="async" />
            </picture>
          </div>
          <div>
            <h1 className="text-5xl lg:text-6xl xl:text-7xl font-bold lg:tracking-tight xl:tracking-tighter">
              Marketing website done with Astro

            </h1>
            <p className="text-lg mt-4 text-slate-600 max-w-xl">
              Astroship is a starter template for startups, marketing websites &amp; landing
              pages.
              <br />
              Built with Astro.build and TailwindCSS. You can quickly
              create any website with this starter.

            </p>
            <div className="mt-6 flex flex-col sm:flex-row gap-3">
              <a href="https://web3templates.com/templates/astroship-starter-website-template-for-astro" rel="noopener" className="rounded text-center transition focus-visible:ring-2 ring-offset-2 ring-gray-200 px-5 py-2.5 bg-black text-white hover:bg-gray-800  border-2 border-transparent flex gap-1 items-center justify-center">
                <svg width="1em" height="1em" viewBox="0 0 24 24" className="text-white w-5 h-5" data-icon="bx:bxs-cloud-download">
                  <path d="M18.944 11.112C18.507 7.67 15.56 5 12 5C9.244 5 6.85 6.61 5.757 9.149C3.609 9.792 2 11.82 2 14c0 2.657 2.089 4.815 4.708 4.971V19H17.99v-.003L18 19c2.206 0 4-1.794 4-4a4.008 4.008 0 0 0-3.056-3.888zM8 12h3V9h2v3h3l-4 5l-4-5z" fill="currentColor">
                  </path>
                </svg>
                Download for Free
              </a>
              <a href="https://github.com/surjithctly/astroship" rel="noopener" className="rounded text-center transition focus-visible:ring-2 ring-offset-2 ring-gray-200 px-5 py-2.5 bg-white border-2 border-black hover:bg-gray-100 text-black  flex gap-1 items-center justify-center">
                <svg width="1em" height="1em" viewBox="0 0 24 24" className="text-black w-4 h-4" data-icon="bx:bxl-github">
                  <path fillRule="evenodd" clipRule="evenodd" d="M12.026 2c-5.509 0-9.974 4.465-9.974 9.974c0 4.406 2.857 8.145 6.821 9.465c.499.09.679-.217.679-.481c0-.237-.008-.865-.011-1.696c-2.775.602-3.361-1.338-3.361-1.338c-.452-1.152-1.107-1.459-1.107-1.459c-.905-.619.069-.605.069-.605c1.002.07 1.527 1.028 1.527 1.028c.89 1.524 2.336 1.084 2.902.829c.091-.645.351-1.085.635-1.334c-2.214-.251-4.542-1.107-4.542-4.93c0-1.087.389-1.979 1.024-2.675c-.101-.253-.446-1.268.099-2.64c0 0 .837-.269 2.742 1.021a9.582 9.582 0 0 1 2.496-.336a9.554 9.554 0 0 1 2.496.336c1.906-1.291 2.742-1.021 2.742-1.021c.545 1.372.203 2.387.099 2.64c.64.696 1.024 1.587 1.024 2.675c0 3.833-2.33 4.675-4.552 4.922c.355.308.675.916.675 1.846c0 1.334-.012 2.41-.012 2.737c0 .267.178.577.687.479C19.146 20.115 22 16.379 22 11.974C22 6.465 17.535 2 12.026 2z" fill="currentColor">
                  </path>
                </svg>
                GitHub Repo

              </a>
            </div>
          </div>
        </main>
        <div className="mt-16 md:mt-0">
          <h2 className="text-4xl lg:text-5xl font-bold lg:tracking-tight">
            Everything you need to start a website

          </h2>
          <p className="text-lg mt-4 text-slate-600">
            Astro comes batteries included. It takes the best parts of state-of-the-art
            tools and adds its own innovations.

          </p>
        </div>
        <div className="grid sm:grid-cols-2 md:grid-cols-3 mt-16 gap-16">
          <div className="flex gap-4 items-start">
            <div className="mt-1 bg-black rounded-full  p-2 w-8 h-8 shrink-0">
              <svg width="1em" height="1em" viewBox="0 0 24 24" className="text-white" data-icon="bx:bxs-briefcase">

                <path d="M20 6h-3V4c0-1.103-.897-2-2-2H9c-1.103 0-2 .897-2 2v2H4c-1.103 0-2 .897-2 2v3h20V8c0-1.103-.897-2-2-2zM9 4h6v2H9V4zm5 10h-4v-2H2v7c0 1.103.897 2 2 2h16c1.103 0 2-.897 2-2v-7h-8v2z" fill="currentColor">
                </path>
              </svg>
            </div>
            <div>
              <h3 className="font-semibold text-lg">Bring Your Own Framework
              </h3>
              <p className="text-slate-500 mt-2 leading-relaxed">Build your site using React, Svelte, Vue, Preact, web components, or just plain ol' HTML + JavaScript.
              </p>
            </div>
          </div>
          <div className="flex gap-4 items-start">
            <div className="mt-1 bg-black rounded-full  p-2 w-8 h-8 shrink-0">
              <svg width="1em" height="1em" viewBox="0 0 24 24" className="text-white" data-icon="bx:bxs-window-alt">

                <path d="M20 3H4c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2h16c1.103 0 2-.897 2-2V5c0-1.103-.897-2-2-2zm-3 3h2v2h-2V6zm-3 0h2v2h-2V6zM4 19v-9h16.001l.001 9H4z" fill="currentColor">
                </path>
              </svg>
            </div>
            <div>
              <h3 className="font-semibold text-lg">100% Static HTML, No JS
              </h3>
              <p className="text-slate-500 mt-2 leading-relaxed">Astro renders your entire page to static HTML, removing all JavaScript from your final build by default.
              </p>
            </div>
          </div>
          <div className="flex gap-4 items-start">
            <div className="mt-1 bg-black rounded-full  p-2 w-8 h-8 shrink-0">
              <svg width="1em" height="1em" viewBox="0 0 24 24" className="text-white" data-icon="bx:bxs-data">

                <path d="M20 6c0-2.168-3.663-4-8-4S4 3.832 4 6v2c0 2.168 3.663 4 8 4s8-1.832 8-4V6zm-8 13c-4.337 0-8-1.832-8-4v3c0 2.168 3.663 4 8 4s8-1.832 8-4v-3c0 2.168-3.663 4-8 4z" fill="currentColor">
                </path>
                <path d="M20 10c0 2.168-3.663 4-8 4s-8-1.832-8-4v3c0 2.168 3.663 4 8 4s8-1.832 8-4v-3z" fill="currentColor">
                </path>
              </svg>
            </div>
            <div>
              <h3 className="font-semibold text-lg">On-Demand Components
              </h3>
              <p className="text-slate-500 mt-2 leading-relaxed">Need some JS? Astro can automatically hydrate interactive components when they become visible on the page.
              </p>
            </div>
          </div>
          <div className="flex gap-4 items-start">
            <div className="mt-1 bg-black rounded-full  p-2 w-8 h-8 shrink-0">
              <svg width="1em" height="1em" viewBox="0 0 24 24" className="text-white" data-icon="bx:bxs-bot">

                <path d="M21 10.975V8a2 2 0 0 0-2-2h-6V4.688c.305-.274.5-.668.5-1.11a1.5 1.5 0 0 0-3 0c0 .442.195.836.5 1.11V6H5a2 2 0 0 0-2 2v2.998l-.072.005A.999.999 0 0 0 2 12v2a1 1 0 0 0 1 1v5a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-5a1 1 0 0 0 1-1v-1.938a1.004 1.004 0 0 0-.072-.455c-.202-.488-.635-.605-.928-.632zM7 12c0-1.104.672-2 1.5-2s1.5.896 1.5 2s-.672 2-1.5 2S7 13.104 7 12zm8.998 6c-1.001-.003-7.997 0-7.998 0v-2s7.001-.002 8.002 0l-.004 2zm-.498-4c-.828 0-1.5-.896-1.5-2s.672-2 1.5-2s1.5.896 1.5 2s-.672 2-1.5 2z" fill="currentColor">
                </path>
              </svg>
            </div>
            <div>
              <h3 className="font-semibold text-lg">Broad Integration
              </h3>
              <p className="text-slate-500 mt-2 leading-relaxed">Astro supports TypeScript, Scoped CSS, CSS Modules, Sass, Tailwind, Markdown, MDX, and any other npm packages.
              </p>
            </div>
          </div>
          <div className="flex gap-4 items-start">
            <div className="mt-1 bg-black rounded-full  p-2 w-8 h-8 shrink-0">
              <svg width="1em" height="1em" viewBox="0 0 24 24" className="text-white" data-icon="bx:bxs-file-find">

                <path d="M6 22h12c.178 0 .348-.03.512-.074l-3.759-3.759A4.966 4.966 0 0 1 12 19c-2.757 0-5-2.243-5-5s2.243-5 5-5s5 2.243 5 5a4.964 4.964 0 0 1-.833 2.753l3.759 3.759c.044-.164.074-.334.074-.512V8l-6-6H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2z" fill="currentColor">
                </path>
                <circle cx="12" cy="14" r="3" fill="currentColor">
                </circle>
              </svg>
            </div>
            <div>
              <h3 className="font-semibold text-lg">SEO Enabled
              </h3>
              <p className="text-slate-500 mt-2 leading-relaxed">Automatic sitemaps, RSS feeds, pagination and collections take the pain out of SEO and syndication. It just works!
              </p>
            </div>
          </div>
          <div className="flex gap-4 items-start">
            <div className="mt-1 bg-black rounded-full  p-2 w-8 h-8 shrink-0">
              <svg width="1em" height="1em" viewBox="0 0 24 24" className="text-white" data-icon="bx:bxs-user">

                <path d="M7.5 6.5C7.5 8.981 9.519 11 12 11s4.5-2.019 4.5-4.5S14.481 2 12 2S7.5 4.019 7.5 6.5zM20 21h1v-1c0-3.859-3.141-7-7-7h-4c-3.86 0-7 3.141-7 7v1h17z" fill="currentColor">
                </path>
              </svg>
            </div>
            <div>
              <h3 className="font-semibold text-lg">Community
              </h3>
              <p className="text-slate-500 mt-2 leading-relaxed">Astro is an open source project powered by hundreds of contributors making thousands of individual contributions.
              </p>
            </div>
          </div>
        </div>
        <div className="mt-24">
          <h2 className="text-center text-slate-500">Works with technologies
          </h2>
          <div className="flex gap-8 md:gap-20 items-center justify-center mt-10 flex-wrap">
            <FaReact className="size-8 md:size-12" />
            <FaHtml5 className="size-8 md:size-12" />
            <RiTailwindCssFill className="size-8 md:size-12" /> 
            <IoLogoElectron  className="size-8 md:size-12" /> 
            <MdOutlinePhp className="size-8 md:size-12" /> 
            <FaCss3Alt className="size-8 md:size-12" /> 
            <RiNextjsLine className="size-8 md:size-12" />  
            <FaPython className="size-8 md:size-12" /> 
            <PiFileCppBold className="size-8 md:size-12" /> 
            <PiFileCSharpBold  className="size-8 md:size-12" /> 
          </div>
        </div>
        <div className="bg-black p-8 md:px-20 md:py-20 mt-20 mx-auto max-w-5xl rounded-lg flex flex-col items-center text-center">
          <h2 className="text-white text-4xl md:text-6xl tracking-tight">
            Build faster websites.

          </h2>
          <p className="text-slate-400 mt-4 text-lg md:text-xl">
            Pull content from anywhere and serve it fast with Astro's next-gen island
            architecture.

          </p>
          <div className="flex mt-5">
            <a href="/" className="rounded text-center transition focus-visible:ring-2 ring-offset-2 ring-gray-200 px-5 py-2.5 bg-white text-black   border-2 border-transparent">Get Started
            </a>
          </div>
        </div>
      </div >


      <Footer />


    </div >
  )
}

export default App
