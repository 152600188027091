import React, { useState } from 'react'

const Footer = () => {
    const [year] = useState(new Date().getFullYear())
    return (
        <footer className="my-20">
            <p className="text-center text-sm text-slate-500">
                Copyright © {year} ValoXity. All rights reserved.

            </p>
            <p className="text-center text-xs text-slate-500 mt-1">
                Made by
                <a href="https://ValoXity.com"  rel="noopener" className="hover:underline">{' '} ValoXityTeam </a>
            </p>
        </footer>
    )
}

export default Footer
